// import store from "@/core/services/store";

const Menu = [
  // {
  //   url: "/dashboard",
  //   title: "Dashboard",
  //   icon: "flaticon2-architecture-and-city",
  //   module: "dashboard",
  // },
  {
    url: "/user-management",
    title: "Manajemen User",
    icon: "flaticon2-avatar",
    submenu: [
      {
        url: "/user",
        title: "User",
        module: "user",
      },
      {
        url: "/zone",
        title: "Wilayah",
        module: "zone",
      },
      {
        url: "/role",
        title: "Role",
        module: "role",
      },
      {
        url: "/module",
        title: "Modul",
        module: "module",
      },
      {
        url: "/action",
        title: "Tindakan",
        module: "action",
      },
    ],
  },
  {
    url: "/dashboard",
    title: "Dashboard",
    icon: "flaticon-dashboard",
    module: "operasional",
  },
  {
    url: "/terminal",
    title: "Sistem Informasi",
    icon: "flaticon2-shelter",
    submenu: [
      {
        url: "/terminal",
        title: "Data Terminal",
        module: "terminal",
      },
      {
        url: "/sdm",
        title: "SDM",
        module: "sdm",
      },
      {
        url: "/pob",
        title: "Perusahaan Otobus",
        module: "pob",
      },
      {
        url: "/trayek",
        title: "Trayek",
        module: "trayek",
      },
      {
        url: "/angkutan",
        title: "Kendaraan",
        module: "angkutan",
      },
      {
        url: "/aset",
        title: "Aset Terminal",
        module: "angkutan",
      },
      {
        url: "/file",
        title: "Image Processing Gate",
        module: "angkutan",
      },
    ],
  },
  {
    url: "/rancang-bangun",
    title: "Rancang Bangun",
    icon: "flaticon2-layers-2",
    submenu: [
      {
        url: "/pintu-masuk",
        title: "Rencana Pembangunan Terminal",
        module: "operasional",
      },
      {
        url: "/pintu-masuk",
        title: "Pengajuan Persetujuan Bangunan",
        module: "operasional",
      },
      {
        url: "/pintu-masuk",
        title: "Proses Pembangunan",
        module: "operasional",
      },
    ],
  },
  {
    // url: "/operasional",
    title: "Operasional Terminal",
    icon: "flaticon2-calendar-5",
    submenu: [
      {
        url: "/operasional",
        title: "Produksi Kendaraan",
        submenu: [
          {
            url: "/pintu-masuk",
            title: "Data Kendaraan Tiba",
            module: "operasional",
          },
          {
            url: "/pintu-keluar",
            title: "Data Kendaraan Keluar",
            module: "operasional",
          },
          {
            // url: "/perizinan",
            title: "Data Perizinan",
            module: "operasional",
            submenu: [
              {
                url: "/spinoam",
                title: "Spinoam",
                module: "operasional",
              },
              {
                url: "/spinoam",
                title: "Eblue",
                module: "operasional",
              },
              {
                url: "/spinoam",
                title: "Rampcheck",
                module: "operasional",
              },
            ],
          },
        ],
      },
      {
        title: "Produksi Penumpang",
        url: "/penumpang",
        submenu: [
          {
            url: "/data-penumpang-tiba",
            title: "Data Penumpang Tiba",
            module: "operasional",
          },
          {
            url: "/data-penumpang-turun",
            title: "Data Penumpang Turun",
            module: "operasional",
          },
          {
            url: "/data-penumpang-naik",
            title: "Data Penumpang Naik",
            module: "operasional",
          },
          {
            url: "/data-penumpang-berangkat",
            title: "Data Penumpang Berangkat",
            module: "operasional",
          },
        ],
      },
      // {
      //   url: "/pintu-keluar",
      //   title: "Pintu Keluar",
      //   module: "operasional",
      // },
    ],
  },
  // {
  //   url: "/angkutan",
  //   title: "Operasional Terminal",
  //   icon: "fas fa-bus",
  //   submenu: [
  //     {
  //       url: "/pob",
  //       title: "Perusahaan Otobus",
  //       module: "pob",
  //     },
  //     {
  //       url: "/trayek",
  //       title: "Trayek",
  //       module: "trayek",
  //     },
  //     {
  //       url: "/angkutan",
  //       title: "Kendaraan",
  //       module: "angkutan",
  //     },
  //   ],
  // },
  // {
  //   url: "/files",
  //   title: "Image Processing Gate",
  //   icon: "flaticon-file-1",
  //   submenu: [
  //     {
  //       url: "/file",
  //       title: "Dokumen",
  //       module: "file",
  //     },
  //     {
  //       url: "/file-category",
  //       title: "Kategori",
  //       module: "file-category",
  //     },
  //   ],
  // },
];

export default Menu;
