var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"to":_vm.to},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
      isActive && 'menu-item-active',
      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[(_vm.submenu)?_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]):(_vm.icon)?_c('i',{class:['menu-icon', _vm.icon]}):_vm._e(),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.title))])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }